export const Theme = {
  brown: "#886B4D",
  brownLight:"#B68453",
  red: "#B11D25",
  redLight: "#C14B41",
  coal: "#31302C",
  beigeLight: "#F2E8D3",
  wineRed: "#832F2C",
  oliveGreen: "#C2C6A4",
  white: "#FFFFFF"
};